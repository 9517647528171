import { useTheme } from 'layout/ThemeContext';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toLocale, toNumber } from 'utils/filters';
import { usePrevious, usePreviousString } from 'utils/hooks/usePrevious';

interface FiltersProps {
    buildingInitial: string[];
    clearFiltersActive: boolean;
    facilitiesInitial: string[];
    floorInitial: string[];
    onHandleClearFilters: () => void;
    priceInitial: string[];
    roomInitial: string[];
    statusInitial: string[];
    yardageInitial: string[];
}

export const useFilters = ({
    clearFiltersActive,
    onHandleClearFilters,
    buildingInitial,
    floorInitial,
    roomInitial,
    yardageInitial,
    priceInitial,
    facilitiesInitial,
    statusInitial,
}: FiltersProps) => {
    const {
        setBuilding,
        setFloor,
        room,
        setRoom,
        yardage,
        setYardage,
        price,
        setPrice,
        facilities,
        setFacilities,
        floor,
        setSortBy,
        sortBy,
        status,
        setStatus,
        building,
        isInitial,
        setIsInitial,
        activeFilter,
        setActiveFilter,
    } = useTheme();

    const [clearButton, setClearButton] = useState(true);

    const optionsBuilding = buildingInitial?.map((item) => ({
        value: item,
        label: item,
    }));

    const optionsFloor = floorInitial?.map((item) => ({
        value: item,
        label: item,
    }));

    const optionsRoom = roomInitial?.map((item) => ({
        value: item,
        label: item,
    }));

    const optionsFacilities = facilitiesInitial?.map((item) => ({
        value: item,
        label: item,
    }));

    const optionsStatuses =
        statusInitial?.map((item) => ({
            value: item,
            label: item,
        })) || [];

    const sortByOption: { label: string; value: string }[] = [
        {
            value: 'Domyślnie',
            label: 'Domyślnie',
        },
        {
            value: 'Najtańszych',
            label: 'Najtańszych',
        },
        { value: 'Najdroższych', label: 'Najdroższych' },
        {
            value: 'Najmniejszej liczby pokoi',
            label: 'Najmniejszej liczby pokoi',
        },
        {
            value: 'Największej liczby pokoi',
            label: 'Największej liczby pokoi',
        },
        { value: 'Najmniejszego metrażu', label: 'Najmniejszego metrażu' },
        { value: 'Największego metrażu', label: 'Największego metrażu' },
    ];

    const prevBuilding: string[] = usePrevious(building);
    const prevFloor: string[] = usePrevious(floor);
    const prevRoom: string[] = usePrevious(room);
    const prevPrice: string[] = usePrevious(price);
    const prevYardage: string[] = usePrevious(yardage);
    const prevFacilities: string[] = usePrevious(facilities);
    const prevSortBy: string = usePreviousString(sortBy);
    const prevStatus: string[] = usePrevious(status);

    useEffect(() => {
        if (isInitial) {
            setYardage(yardageInitial);
            setPrice(priceInitial);
            setFacilities(facilitiesInitial);
        }
    }, []);

    const { control, watch, reset } = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {
            investmentBuilding:
                building.length !== 0
                    ? optionsBuilding.filter((item1) =>
                          building.some((item2) => item1.value === item2),
                      )
                    : optionsBuilding,
            buildingFloor:
                floor.length !== 0
                    ? optionsFloor.filter((item1) =>
                          floor.some((item2) => item1.value === item2),
                      )
                    : optionsFloor,
            flatRoomsQuantity:
                room.length !== 0
                    ? optionsRoom.filter((item1) =>
                          room.some((item2) => item1.value === item2),
                      )
                    : optionsRoom,
            udogodnienia:
                facilities.length !== 0
                    ? optionsFacilities.filter((item1) =>
                          facilities.some((item2) => item1.value === item2),
                      )
                    : optionsFacilities,
            sortowanie: { value: sortBy, label: sortBy },
            status:
                status?.length !== 0
                    ? optionsStatuses?.filter((item1) =>
                          status?.some((item2) => item1.value === item2),
                      )
                    : optionsStatuses,
        },
    });

    const inputBuilding = watch('investmentBuilding');
    const inputFloor = watch('buildingFloor');
    const inputRoom = watch('flatRoomsQuantity');
    const inputFacilities = watch('udogodnienia');
    const inputSortBy = watch('sortowanie');
    const inputStatus = watch('status');

    useEffect(() => {
        if (clearFiltersActive) {
            reset();
            setBuilding(buildingInitial);
            setFloor(floorInitial);
            setRoom(roomInitial);
            setYardage(yardageInitial);
            setPrice(priceInitial);
            setFacilities(facilitiesInitial);
            setSortBy('Domyślnie');
            setStatus(statusInitial);
            onHandleClearFilters();
            setIsInitial(true);
        }
    }, [clearFiltersActive]);

    useEffect(() => {
        setIsInitial(false);
    }, [
        inputBuilding,
        inputFloor,
        inputRoom,
        inputFacilities,
        inputSortBy,
        inputStatus,
    ]);

    useEffect(() => {
        const filtered = inputBuilding?.map(
            (val: { value: string; label: string }) => val.value,
        );

        setBuilding(filtered);
    }, [inputBuilding]);

    useEffect(() => {
        const filtered = inputFloor?.map(
            (val: { value: string; label: string }) => val.value,
        );
        setFloor(filtered);
    }, [inputFloor]);

    useEffect(() => {
        const filtered = inputRoom?.map(
            (val: { value: string; label: string }) => val.value,
        );
        setRoom(filtered);
    }, [inputRoom]);

    useEffect(() => {
        const filtered = inputFacilities?.map(
            (val: { value: string; label: string }) => val.value,
        );

        setFacilities(filtered);
    }, [inputFacilities]);

    useEffect(() => {
        setSortBy(inputSortBy.value);
    }, [inputSortBy]);

    useEffect(() => {
        const filtered = inputStatus?.map(
            (val: { value: string; label: string }) => val.value,
        );

        setStatus(filtered);
    }, [inputStatus]);

    useEffect(() => {
        const equals = (filter: string[], initial: string[]) =>
            filter.length === initial.length &&
            filter.every((v, i) => v === initial[i]);
        const equalsString = (filter: string, initial: string) =>
            filter === initial;

        const prev =
            equals(prevBuilding, building) &&
            equals(prevRoom, room) &&
            equals(prevFloor, floor) &&
            equals(prevPrice, price) &&
            equals(prevYardage, yardage) &&
            equals(prevFacilities, facilities) &&
            equalsString(prevSortBy, sortBy) &&
            equals(prevStatus, status);

        const initial =
            equals(buildingInitial, building) &&
            equals(roomInitial, room) &&
            equals(floorInitial, floor) &&
            equals(priceInitial, price) &&
            equals(yardageInitial, yardage) &&
            equals(facilitiesInitial, facilities) &&
            equalsString('Domyślnie', sortBy) &&
            equals(statusInitial, status);

        const isClear: boolean = prev && !initial;

        setClearButton(isClear);
    }, [building, room, floor, price, yardage, facilities, sortBy, status]);

    const handleOnYardageChange = ({
        min,
        max,
    }: {
        min: number;
        max: number;
    }) => {
        const valMin = min?.toLocaleString('pl');
        const valMax = max?.toLocaleString('pl');
        setYardage([valMin, valMax]);
        setIsInitial(false);
    };

    const handleOnMinYardage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYardage((prevState: string[]) => [
            toLocale(event.target.value),
            prevState[1],
        ]);
    };

    const handleOnMaxYardage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setYardage((prevState: string[]) => [
            prevState[0],
            toLocale(event.target.value),
        ]);
    };

    const handleOnMaxBlurYardage = (
        event: React.FocusEvent<HTMLInputElement>,
    ) => {
        const val = toNumber(event.target.value);
        const max = toNumber(yardageInitial[1]);
        const min = toNumber(yardage[0]);

        if (val > max) {
            setYardage((prevState: number[]) => [
                prevState[0],
                max?.toLocaleString('pl'),
            ]);
        } else if (val <= min) {
            setYardage((prevState: number[]) => [
                prevState[0],
                (min + 1)?.toLocaleString('pl'),
            ]);
        }
    };

    const handleOnMinBlurYardage = (
        event: React.FocusEvent<HTMLInputElement>,
    ) => {
        const val = toNumber(event.target.value);
        const min = toNumber(yardageInitial[0]);

        const max = toNumber(yardage[1]);

        if (val < min) {
            setYardage((prevState: number[]) => [
                min?.toLocaleString('pl'),
                prevState[1],
            ]);
        } else if (val >= max) {
            setYardage((prevState: number[]) => [
                (max - 1)?.toLocaleString('pl'),
                prevState[1],
            ]);
        }
    };

    const handleOnPriceChange = ({
        min,
        max,
    }: {
        min: number;
        max: number;
    }) => {
        const valMin = min?.toLocaleString('pl');
        const valMax = max?.toLocaleString('pl');
        setPrice([valMin, valMax]);
        setIsInitial(false);
    };

    const handleOnMinPriceChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPrice((prevState: string[]) => [
            toLocale(event.target.value),
            prevState[1],
        ]);
    };

    const handleOnMaxPriceChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setPrice((prevState: string[]) => [
            prevState[0],
            toLocale(event.target.value),
        ]);
    };

    const handleOnMaxBlurPrice = (
        event: React.FocusEvent<HTMLInputElement>,
    ) => {
        const val = toNumber(event.target.value);
        const max = toNumber(priceInitial[1]);
        const min = toNumber(price[0]);

        if (val > max) {
            setPrice((prevState: number[]) => [
                prevState[0],
                max?.toLocaleString('pl'),
            ]);
        } else if (val <= min) {
            setPrice((prevState: number[]) => [
                prevState[0],
                (min + 1)?.toLocaleString('pl'),
            ]);
        }
    };

    const handleOnMinBlurPrice = (
        event: React.FocusEvent<HTMLInputElement>,
    ) => {
        const val = toNumber(event.target.value);
        const min = toNumber(priceInitial[0]);
        const max = toNumber(price[1]);

        if (val < min) {
            setPrice((prevState: number[]) => [
                min?.toLocaleString('pl'),
                prevState[1],
            ]);
        } else if (val >= max) {
            setPrice((prevState: number[]) => [
                (max - 1)?.toLocaleString('pl'),
                prevState[1],
            ]);
        }
    };

    const isActiveButton = (state: string[], value: string): boolean => {
        return (state?.includes(value) && state.length == 1) as boolean;
    };
    const handleOnGardenClick = () => {
        setFacilities((prevState: string[]) =>
            isActiveButton(prevState, 'Ogródek')
                ? facilitiesInitial
                : ['Ogródek'],
        );
    };

    const handleOnBalconClick = () => {
        setFacilities((prevState: string[]) =>
            isActiveButton(prevState, 'Balkon')
                ? facilitiesInitial
                : ['Balkon'],
        );
    };

    return {
        clearButton,
        control,
        facilities,
        handleOnBalconClick,
        handleOnGardenClick,
        handleOnMaxBlurPrice,
        handleOnMaxBlurYardage,
        handleOnMaxPriceChange,
        handleOnMaxYardage,
        handleOnMinBlurPrice,
        handleOnMinBlurYardage,
        handleOnMinPriceChange,
        handleOnMinYardage,
        handleOnPriceChange,
        handleOnYardageChange,
        isActiveButton,
        optionsBuilding,
        optionsFacilities,
        optionsFloor,
        optionsRoom,
        optionsStatuses,
        price,
        sortByOption,
        yardage,
    };
};
