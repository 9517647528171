import { EmptyState } from 'components/EmptyState/EmptyState';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React, { useEffect, useState, useMemo } from 'react';
import { useFilters, useResizeColumns, useSortBy, useTable } from 'react-table';
import { columns, orderByFn } from 'shared/columns';
import {
    TableNotSortIcon,
    TableSortDownIcon,
    TableSortUpIcon,
} from 'shared/svg/tableSortIcon';
import {
    FlatsTableWrapper,
    LoadMoreButton,
    SortHeaderWrapper,
    TableWrapper,
} from 'styles/Table.styled';
import { SingleFlatType } from 'types/CommonTypes';
interface FlatsTableProps {
    data: SingleFlatType[];
    investmentSlug: string;
}

export const FlatsTable = ({ data, investmentSlug }: FlatsTableProps) => {
    const [numberOfRows, setNumberOfRows] = useState(9);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'flatStatus',
                    },
                ],
            },
            disableMultiSort: true,
            orderByFn,
        } as any,
        useFilters,
        useResizeColumns,
        useSortBy,
    );

    const { building, floor, room, yardage, price, facilities } = useTheme();

    useEffect(() => {
        setFilter('investmentBuilding', building);
    }, [building]);

    useEffect(() => {
        setFilter('buildingFloor', floor);
    }, [floor]);

    useEffect(() => {
        setFilter('flatRoomsQuantity', room);
    }, [room]);

    useEffect(() => {
        const unformated = [
            Number(yardage[0]?.toString().replace(/\s/g, '')),
            Number(yardage[1]?.toString().replace(/\s/g, '')),
        ];
        setFilter('flatArea', unformated);
    }, [yardage]);

    useEffect(() => {
        const unformated = [
            Number(price[0]?.replace(/\s/g, '')),
            Number(price[1]?.replace(/\s/g, '')),
        ];
        setFilter('flatPrice', unformated);
    }, [price]);

    useEffect(() => {
        setFilter('flatAmenities', facilities);
    }, [facilities]);

    const firstPageRows = rows.slice(0, numberOfRows);
    const loadMore = () => {
        setNumberOfRows((prevState) => prevState + 5);
    };

    if (!rows.length) {
        return (
            <EmptyState
                text="Nie znaleźliśmy żadnych mieszkań zgodnych z Twoimi wymaganiami.
            Zmień lub wyczyść filtry."
            />
        );
    }
    return (
        <FlatsTableWrapper>
            <TableWrapper>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups?.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers?.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                        )}
                                    >
                                        <SortHeaderWrapper>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <TableSortDownIcon />
                                                    ) : (
                                                        <TableSortUpIcon />
                                                    )
                                                ) : (
                                                    <TableNotSortIcon />
                                                )}
                                            </span>
                                        </SortHeaderWrapper>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {firstPageRows?.map((row: any) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={() =>
                                        navigate(
                                            '/' +
                                                investmentSlug +
                                                '/mieszkanie/' +
                                                row.original
                                                    .investmentBuilding +
                                                '/' +
                                                row.original.flatNumber,
                                        )
                                    }
                                >
                                    {row.cells?.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </TableWrapper>
            {rows.length > numberOfRows && (
                <LoadMoreButton onClick={loadMore}>
                    Załaduj więcej
                </LoadMoreButton>
            )}
        </FlatsTableWrapper>
    );
};
