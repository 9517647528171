import { EmptyState } from 'components/EmptyState/EmptyState';
import {
    BottomTitle,
    BottomTitleWrapper,
    CardTitle,
    FlatStatus,
    InfoText,
    PriceText,
    RowWrapper,
    StyledButton,
    TitleWrapper,
} from 'components/FlatCard/FlatCard.styled';
import { HeartAddToFav } from 'components/HeartAddToFav/HeartAddToFav';
import React, { useState } from 'react';
import { SingleFlatType } from 'types/CommonTypes';
import {
    FlatCardWrapper,
    FlatsListMobileWrapper,
    LoadMoreStyled,
} from './FlatsListMobile.styled';
import { useFilteredData } from './useFilteredFlats';

export interface FlatsHeroMobileProps {
    flatsData: SingleFlatType[];
    investmentSlug: string;
}

export const FlatsListMobile = ({
    flatsData,
    investmentSlug,
}: FlatsHeroMobileProps) => {
    const [numberOfRows, setNumberOfRows] = useState(5);

    const loadMore = () => {
        setNumberOfRows((prevState) => prevState + 5);
    };

    const { filteredData } = useFilteredData(flatsData);

    const cutFlats = filteredData.slice(0, numberOfRows);

    if (!cutFlats.length) {
        return (
            <EmptyState
                text="Nie znaleźliśmy żadnych mieszkań zgodnych z Twoimi wymaganiami.
    Zmień lub wyczyść filtry."
            />
        );
    }

    return (
        <FlatsListMobileWrapper>
            {cutFlats?.map((flat: SingleFlatType, index: number) => {
                return (
                    <FlatCardWrapper key={index}>
                        <TitleWrapper>
                            <CardTitle>Mieszkanie {flat?.flatNumber}</CardTitle>
                            <HeartAddToFav
                                building={flat?.investmentBuilding}
                                id={String(flat?.flatNumber)}
                                size={20}
                            />
                        </TitleWrapper>
                        <BottomTitleWrapper>
                            <BottomTitle>
                                budynek {flat?.investmentBuilding}
                            </BottomTitle>
                            •
                            <FlatStatus flatIsFree={flat?.flatStatus}>
                                {flat?.flatStatus}
                            </FlatStatus>
                        </BottomTitleWrapper>
                        <RowWrapper>
                            <InfoText bold>Piętro</InfoText>
                            <InfoText>{flat?.buildingFloor}</InfoText>
                        </RowWrapper>
                        <RowWrapper>
                            <InfoText bold>Ilość Pokoi</InfoText>
                            <InfoText>{flat?.flatRoomsQuantity}</InfoText>
                        </RowWrapper>
                        <RowWrapper>
                            <InfoText bold>Metraż</InfoText>
                            <InfoText noCapitalize>
                                {flat?.flatArea} m<sup>2</sup>
                            </InfoText>
                        </RowWrapper>
                        <RowWrapper>
                            <InfoText bold>Zalety</InfoText>
                            <InfoText>
                                {flat?.flatAmenities}
                                {flat?.flatFeatured && ', Wykończone'}
                            </InfoText>
                        </RowWrapper>
                        <RowWrapper>
                            <InfoText bold noCapitalize>
                                Cena za m<sup>2</sup>
                            </InfoText>
                            <InfoText noCapitalize>
                                {flat?.priceMeter?.toLocaleString('pl')} zł
                            </InfoText>
                        </RowWrapper>
                        <RowWrapper>
                            <InfoText bold>Cena</InfoText>
                            <PriceText>
                                {flat?.flatPrice?.toLocaleString('pl')} zł
                            </PriceText>
                        </RowWrapper>
                        <StyledButton
                            to={
                                '/' +
                                investmentSlug +
                                '/mieszkanie/' +
                                flat?.investmentBuilding +
                                '/' +
                                flat?.flatNumber
                            }
                        >
                            Przejdź do mieszkania{' '}
                            <svg
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                            >
                                <path
                                    d="M14.9297 5.92969L20.9997 11.9997L14.9297 18.0697"
                                    stroke="#466BA2"
                                    strokeWidth="2"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M4.5 12L20.5 12"
                                    stroke="#466BA2"
                                    strokeWidth="2"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </StyledButton>
                    </FlatCardWrapper>
                );
            })}
            {flatsData.length > numberOfRows &&
                cutFlats.length >= numberOfRows && (
                    <LoadMoreStyled onClick={loadMore}>
                        Załaduj więcej
                    </LoadMoreStyled>
                )}
        </FlatsListMobileWrapper>
    );
};
