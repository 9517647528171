import styled, { css } from 'styled-components';
import { H3 } from 'styles/Typography.styled';
import Select from 'react-select';

export const InputWrapper = styled.div`
    display: grid;
    gap: 0 20px;
    grid-template-columns: 1fr 1fr;
    padding-top: 18px;
`;

export const Wrapper = styled.div`
    overflow-y: scroll;
    height: 100%;
`;

export const MobileFiltersWrapper = styled.div`
    bottom: 0;
    min-height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
`;

export const FiltersContainer = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue60};
        min-height: 100%;
        padding: 100px 20px 92px;
        width: 100%;
    `,
);

export const StyledSelect = styled(Select)``;

export const FiltersBox = styled.div`
    display: grid;
    grid-gap: 32px;
    width: 100%;
`;

export const ButtonsWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.white};
        border-top: 1px solid ${colors.neutral200};
        display: grid;
        height: 82px;
        padding: 16px 0;
        place-content: center;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 9;
    `,
);

export const FiltersHeader = styled.header(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue60};
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 40px 20px 32px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
    `,
);

export const FilterBox = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
`;

export const SortWrapper = styled.div`
    text-align: start;
`;

export const FiltersTitle = styled(H3)(
    ({ theme: { letterSpacing } }) =>
        css`
            line-height: 29px;
            letter-spacing: ${letterSpacing.xs};
        `,
);

export const FilterBoxRowDivided = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
`;
