import { InputNumber } from 'components/InputNumber/InputNumber';
import { RangeSlider } from 'components/RangeSlider/RangeSlider';
import { Select } from 'components/Select/Select';
import React, { useState } from 'react';
import { CloseIcon } from 'shared/svg/closeIcon';
import { SecondaryButton } from 'styles/Typography.styled';
import { toNumber } from 'utils/filters';
import {
    ButtonsWrapper,
    FilterBox,
    FiltersBox,
    InputWrapper,
    FiltersTitle,
    MobileFiltersWrapper,
    FiltersHeader,
    FiltersContainer,
    Wrapper,
    StyledSelect,
    SortWrapper,
    FilterBoxRowDivided,
} from './MobileFilters.styled';
import { useFilters } from 'components/TableFilters/useFilters';
import { customStyles, Label } from 'components/Select/Select.styled';
import { Controller } from 'react-hook-form';
import { useTheme } from 'layout/ThemeContext';

interface ITableFiltersProps {
    buildingInitial: string[];
    facilitiesInitial: string[];
    floorInitial: string[];
    priceInitial: string[];
    roomInitial: string[];
    setShowModal: (value: boolean) => void;
    statusInitial: string[];
    yardageInitial: string[];
}

export const MobileFilters = ({
    buildingInitial,
    facilitiesInitial,
    floorInitial,
    priceInitial,
    roomInitial,
    setShowModal,
    statusInitial,
    yardageInitial,
}: ITableFiltersProps) => {
    const [clearFiltersActive, setClearFiltersActive] = useState(false);
    const { numberFilter, activeFilter, setActiveFilter } = useTheme();

    const onHandleClearFilters = () => {
        setClearFiltersActive((prev) => !prev);
        setShowModal(false);
        setActiveFilter(false);
    };
    const {
        clearButton,
        control,
        handleOnMaxBlurPrice,
        handleOnMaxBlurYardage,
        handleOnMaxPriceChange,
        handleOnMaxYardage,
        handleOnMinBlurPrice,
        handleOnMinBlurYardage,
        handleOnMinPriceChange,
        handleOnMinYardage,
        handleOnPriceChange,
        handleOnYardageChange,
        optionsBuilding,
        optionsFacilities,
        optionsFloor,
        optionsRoom,
        optionsStatuses,
        price,
        sortByOption,
        yardage,
    } = useFilters({
        clearFiltersActive,
        onHandleClearFilters,
        buildingInitial,
        floorInitial,
        roomInitial,
        facilitiesInitial,
        yardageInitial,
        priceInitial,
        statusInitial,
    });

    const handleClearFilters = () => {
        setClearFiltersActive((prev) => !prev);
    };
    const handleSetFilters = () => {
        setActiveFilter(true);
        setShowModal(false);
    };

    return (
        <MobileFiltersWrapper>
            <Wrapper>
                <FiltersContainer>
                    <FiltersHeader>
                        <FiltersTitle>Filtry</FiltersTitle>
                        <div onClick={() => setShowModal(false)}>
                            <CloseIcon color="#20263B" />
                        </div>
                    </FiltersHeader>
                    <FiltersBox>
                        <FilterBoxRowDivided>
                            <SortWrapper>
                                <Label>Sortuj</Label>
                                <Controller
                                    control={control}
                                    name="sortowanie"
                                    render={({
                                        field: { onChange, name, value },
                                    }) => (
                                        <StyledSelect
                                            options={sortByOption}
                                            name={name}
                                            styles={customStyles}
                                            onChange={onChange}
                                            placeholder="wybierz ..."
                                            value={value}
                                        />
                                    )}
                                />
                            </SortWrapper>
                            <SortWrapper>
                                <Select
                                    options={optionsStatuses}
                                    isMulti
                                    name="status"
                                    control={control}
                                    label="Status"
                                />
                            </SortWrapper>
                        </FilterBoxRowDivided>

                        <FilterBox>
                            <Select
                                options={optionsBuilding}
                                isMulti
                                name="investmentBuilding"
                                control={control}
                                label="Budynek"
                            />
                            <Select
                                options={optionsFloor}
                                isMulti
                                name="buildingFloor"
                                control={control}
                                label="Piętro"
                            />
                        </FilterBox>
                        <FilterBox>
                            <Select
                                options={optionsRoom}
                                isMulti
                                name="flatRoomsQuantity"
                                control={control}
                                label="Ilość pokoi"
                            />
                            <Select
                                options={optionsFacilities}
                                isMulti
                                name="udogodnienia"
                                control={control}
                                label="Zalety"
                            />
                        </FilterBox>
                        <RangeSlider
                            label={`METRAŻ (m${'\u00B2'})`}
                            min={toNumber(yardageInitial[0])}
                            max={toNumber(yardageInitial[1])}
                            minValue={toNumber(yardage[0])}
                            maxValue={toNumber(yardage[1])}
                            onChange={handleOnYardageChange}
                        />
                        <InputWrapper>
                            <InputNumber
                                text="od"
                                value={yardage[0]}
                                onChange={handleOnMinYardage}
                                onBlur={handleOnMinBlurYardage}
                            />
                            <InputNumber
                                text="do"
                                value={yardage[1]}
                                onChange={handleOnMaxYardage}
                                onBlur={handleOnMaxBlurYardage}
                            />
                        </InputWrapper>
                        <RangeSlider
                            label="CENA (zł)"
                            min={toNumber(priceInitial[0])}
                            max={toNumber(priceInitial[1])}
                            minValue={toNumber(price[0])}
                            maxValue={toNumber(price[1])}
                            onChange={handleOnPriceChange}
                        />
                        <InputWrapper>
                            <InputNumber
                                text="od"
                                value={price[0]}
                                onChange={handleOnMinPriceChange}
                                onBlur={handleOnMinBlurPrice}
                            />
                            <InputNumber
                                text="do"
                                value={price[1]}
                                onChange={handleOnMaxPriceChange}
                                onBlur={handleOnMaxBlurPrice}
                            />
                        </InputWrapper>
                    </FiltersBox>
                </FiltersContainer>
                <ButtonsWrapper>
                    {clearButton && activeFilter ? (
                        <SecondaryButton
                            as="button"
                            onClick={() => {
                                handleClearFilters();
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                            }}
                        >
                            Wyczyść filtry
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton
                            as="button"
                            onClick={() => {
                                handleSetFilters();
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                            }}
                        >
                            Zastosuj filtry
                        </SecondaryButton>
                    )}{' '}
                </ButtonsWrapper>
            </Wrapper>
        </MobileFiltersWrapper>
    );
};
